<template>
  <div>
    <h1>Beszámolósablon</h1>
    <v-btn color="primary" rounded :href="`${this.axios.defaults.baseURL}competition/${competitionId}/docx/report`" target="_blank"><v-icon>fa-download</v-icon>Beszámolósablon letöltése</v-btn>
    <h1>Beszámoló kezelése</h1>
    <span v-if="hasReport">
      <v-btn color="success" rounded :href="`${this.axios.defaults.baseURL}competition/${competitionId}/report`" target="_blank"><v-icon>fa-download</v-icon>Letöltés</v-btn>
      <v-btn color="red accent-4" rounded v-if="editable" @click="deleteReport()"><v-icon>fa-trash</v-icon>Beszámoló törlése</v-btn>
    </span>
    <span v-else>
      <v-btn color="success" rounded v-if="editable" @click="dialogs.addReport.show=true"><v-icon>fa-upload</v-icon>Kész beszámoló feltöltése PDF-ben</v-btn>
      <span v-else>NINCS BESZÁMOLÓ FELTÖLTVE!!!</span>
    </span>

    <h1>Mellékletek</h1>
    <v-btn color="success" v-if="editable" rounded @click="dialogs.addMedium.show=true"><v-icon>fa-upload</v-icon>Feltöltés</v-btn>

    <h2 v-if="media.filter(M => !/^image/.test(M.metadata.mimeType)).length>0">Letölthető fájlok</h2>
    <div  v-for="file in media.filter(M => !/^image/.test(M.metadata.mimeType))" :key="file.id">
      <a :download="file.metadata.originalName" :href="`data:${file.metadata.mimeType};base64,${file.buffer}`">{{file.metadata.originalName}}</a>
      <v-btn v-if="editable" color="red accent-4" rounded @click="deleteMedium(file.id)">Fájl Törlése<v-icon>fa-trash</v-icon></v-btn>
    </div>
    <v-carousel hide-delimiters v-if="media.filter(M => /^image/.test(M.metadata.mimeType)).length>0">
      <v-carousel-item v-for="img in media.filter(M => /^image/.test(M.metadata.mimeType))" :key="img.id" :src="`data:${img.metadata.mimeType};base64,${img.buffer}`" contain>
        <v-btn v-if="editable" color="red accent-4" rounded @click="deleteMedium(img.id)">Kép törlése<v-icon>fa-trash</v-icon></v-btn>
      </v-carousel-item>
    </v-carousel>

    <v-dialog
      v-model="dialogs.addReport.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Beszámoló feltöltése PDF-ben</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-file-input
              v-model="dialogs.addReport.file"
              rounded
              label="Beszámoló"
              outlined
              accept="application/pdf"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.addReport.show = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            v-if="dialogs.addReport.file"
            @click="addReport()"
          >
            Feltöltés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogs.addMedium.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Mellékletek feltöltése</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-file-input
              v-model="dialogs.addMedium.file"
              rounded
              label="Fájl"
              outlined
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.addMedium.show = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            v-if="dialogs.addMedium.file"
            @click="addMedium()"
          >
            Feltöltés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'report',
  props: [
    "competitionId",
    "editable"
  ],
  data () {
    return {
      media: [],
      hasReport: false,
      dialogs: {
        addMedium: {
          show: false,
          file: null
        },
        addReport: {
          show: false,
          file: null
        }
      }
    }
  },
  computed: {

  },
  methods: {
    addReport: function() {
      var formData = new FormData();
      formData.append('file',this.dialogs.addReport.file);
      this.axios.post(`competition/${this.competitionId}/report`, formData, {headers: { 'Content-Type': 'multipart/form-data'}}).then((response) => {
        if(response.data.success) {
          this.dialogs.addReport.show=false;
          this.dialogs.addReport.file=null
          this.$store.commit('setSnack', 'Sikeresen feltöltve.')
          this.hasReport=true
        }
      })
    },
    deleteReport: function() {
      this.axios({url: `competition/${this.competitionId}/report`, method: 'DELETE'}).then(response => {
        if(response.data.success) {
          this.hasReport=false;
          this.$store.commit('setSnack', 'Sikeresen törölve.')
        }
      })
    },
    getHasReport: function() {
      this.axios({url: `competition/${this.competitionId}/hasReport`, method: 'GET'}).then(response => {
        if(response.data.success) this.hasReport=response.data.data.hasReport;
      })
    },
    addMedium: function() {
      var formData = new FormData();
      formData.append('file',this.dialogs.addMedium.file);
      this.axios.post(`competition/${this.competitionId}/report/media`, formData, {headers: { 'Content-Type': 'multipart/form-data'}}).then(async (response) => {
        if(response.data.success) {
          this.dialogs.addMedium.show=false;
          this.media.push(response.data.data.medium)
          this.$store.commit('setSnack', 'Sikeresen feltöltve.')
        }
      })
    },
    deleteMedium: function(id) {
      this.axios({url: `competition/${this.competitionId}/report/media/${id}`, method: 'DELETE'}).then(response => {
        if(response.data.success) {
          this.media.splice(this.media.findIndex(m => m.id==id),1)
          this.$store.commit('setSnack', 'Sikeresen törölve.')
        }
      })
    },
    getMedia: function() {
      this.axios({url: `competition/${this.competitionId}/report/media`, method: 'GET'}).then(response => {
        if(response.data.success) {
          this.media = response.data.data.media
        }
      })
    },
    toBase64: file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })

  },
  mounted() {
    this.getMedia()
    this.getHasReport()
  }
}
</script>
