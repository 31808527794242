<!--TODO iskolák-->
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="appliedTeams"
    >
      <template v-slot:item.ageGroup="{item}">
        {{ageGroupColumn(item.AgeGroupId)}}
      </template>

      <template v-slot:item.clubOrSchool="{item}">
        {{item.KAClub?item.KAClub.MIRClub.name:'-'}}
      </template>

      <template v-slot:item.actions="{item}">
        <v-tooltip top>
          {{editable? "Megtekintés és szerkesztés":"Megtekintés"}}
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs" @click="viewTeam(item)" color="primary"><v-icon>{{editable? "fa-edit":"fa-eye"}}</v-icon></v-btn>
          </template>
        </v-tooltip>
        <v-tooltip top>
          Törlés
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs" :disabled="!canDeleteTeam" @click="dialogs.deleteTeam.teamId=item.id, dialogs.deleteTeam.show=true" color="red accent-4"><v-icon>fa-trash</v-icon></v-btn>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:item.coaches="{item}">
        <v-chip-group>
          <v-chip
            v-for="coach in item.Coaches"
            :key="coach.id"
            color="primary"
          >
            {{coach.fullname}}
          </v-chip>
        </v-chip-group>
      </template>
    </v-data-table>
    <appliedTeamDetail :clubId="dialogs.editTeam.clubId" :competitionId="competitionId" :ageGroups="ageGroups" :editable="editable" :newTeam="false" :team="dialogs.editTeam.editedTeam" @close="dialogs.editTeam.show=false" @change="dialogs.editTeam.show=false" :show="dialogs.editTeam.show"/>
    <v-dialog
      v-model="dialogs.deleteTeam.show"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-text>
          <span class="headline">Csapat törlése</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            Biztosan szeretné törölni a csapat nevezését?
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.deleteTeam.show=false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteTeam()"
          >
            Törlés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import appliedTeamDetail from './competitionApplication-appliedTeamDetail'

export default {
  props: ['competitionId', 'editable', 'appliedTeams', "ageGroups", "canDeleteTeam", "showCoaches"],
  components: {
    appliedTeamDetail
  },
  data: function(){
    return {
      headers: [
        {
          text: "Egyesület/iskola",
          align: "center",
          sortable: false,
          filterable: false,
          groupable: false,
          value: "clubOrSchool"
        },
        {
          text: "Csapatnév",
          align: "center",
          sortable: false,
          filterable: false,
          groupable: false,
          value: "name"
        },
        {
          text: "Korcsoport",
          align: "center",
          sortable: false,
          filterable: false,
          groupable: false,
          value: "ageGroup"
        },
        ...this.showCoaches? [{
          text: "Edzők",
          align: "center",
          sortable: false,
          filterable: false,
          groupable: false,
          value: "coaches"
        }]:[ ],
        {
          text: "Műveletek",
          align: "center",
          sortable: false,
          filterable: false,
          groupable: false,
          value: "actions"
        },
      ],

      dialogs: {
        editTeam: {
          show: false,
          clubId: null,
          editedTeam: {
            name: '',
            AgeGroupId: undefined,
            Coaches: [],
            KidAthletes: []
          },
        },
        deleteTeam: {
          show: false,
          teamId: undefined
        }
      }
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    deleteTeam: function() {
      this.axios({url: `competition/${this.competitionId}/team/${this.dialogs.deleteTeam.teamId}`, method: "DELETE"}).then(response => {
        if(response.data.success) {
          this.dialogs.deleteTeam.show=false;
          this.appliedTeams.splice(this.appliedTeams.findIndex(T => T.id==this.dialogs.deleteTeam.teamId),1)
          this.$store.commit('setSnack', 'Törölve.')
        }
      })
    },
    viewTeam: function(item) {
      this.dialogs.editTeam.editedTeam=item
      this.dialogs.editTeam.clubId=item.KAClub.id
      this.dialogs.editTeam.show=true
      return true
    },
    ageGroupColumn: function(aGId) {
      const aG = this.ageGroups.find(aG => aG.id==aGId)
      return aG?`${aG.shortname} - ${aG.name}`:'-'
    }
  },

}


</script>
