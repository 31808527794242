
<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
    >

      <v-card>
        <v-card-text>
          <span class="headline">Csapat adatai</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-text-field
              :readonly="!editable||!newTeam"
              v-model="team.name"
              label="Csapatnév"
              rounded
              outlined
              prepend-inner-icon="fa-users"
            />
            <v-select
              :readonly="!editable||!newTeam"
              v-model="team.AgeGroupId"
              label="Korcsoport"
              rounded
              outlined
              prepend-inner-icon="fa-child"
              :items="ageGroups"
              item-text="shortname"
              item-value="id"
            />
            <span>
              Edzők:
              <v-chip-group>
                <v-chip
                  v-for="coach in team.Coaches"
                  :key="coach.id"
                  :close="editable&&(coach.id!=$store.getters.userId||newTeam)"
                  @click:close="deleteCoach(coach.id)"
                  color="primary"
                >
                  {{coach.fullname}}
                </v-chip>
                <v-chip
                  color="success"
                  v-if="editable"
                  @click="addCoachDialog()"
                >
                  Edző hozzáadása
                </v-chip>
              </v-chip-group>
            </span>
            Versenyző kölyökatléták:
            <v-data-table
              disable-pagination
              hide-default-footer
              sort-by="serialNumber"
              :items="team.KidAthletes.map(KA => {
                return {
                  ageByYears: KA.ageByYears,
                  id: KA.id,
                  fullname: KA.fullname,
                  serialNumber: KA.KidAthlete_Team.serialNumber,
                  birthdate: KA.birthdate,
                  birthplace: KA.birthplace,
                  guest: KA.KidAthlete_Team.guest
                }
                })"
              :headers="tables.kidAthletes.headers"
            >
              <template v-slot:top v-if="editable">
                <v-tooltip top>
                  {{(team.KidAthletes.length < config.maxKidAthletesInATeam)?'Kölyökatléta hozzáadása':'Elérte a maximális csapatlétszámot'}}
                  <template v-slot:activator="{on, attrs}">
                    <v-btn :disabled="team.KidAthletes.length>=config.maxKidAthletesInATeam" v-on="on" v-bind="attrs" fab @click="addKidAthleteDialog" color="success"><v-icon>fa-user-plus</v-icon></v-btn>
                  </template>
                </v-tooltip>
              </template>
              <template v-slot:item.actions="{item}">
                <v-btn icon v-if="item.serialNumber>1" :disabled="!editable" @click="move(item.id, item.serialNumber, item.serialNumber-1)"><v-icon>fa-arrow-up</v-icon></v-btn>
                <v-btn icon v-if="item.serialNumber<config.maxKidAthletesInATeam&&item.serialNumber<team.KidAthletes.length" :disabled="!editable" @click="move(item.id, item.serialNumber, item.serialNumber+1)"><v-icon>fa-arrow-down</v-icon></v-btn>
                <v-btn icon :disabled="!editable" @click="deleteKidAthlete(item.id, item.serialNumber)" color="red accent-4"><v-icon>fa-trash</v-icon></v-btn>
              </template>
              <template v-slot:item.guest="{item}">
                <v-checkbox readonly :key="item.guest" v-model="item.guest" @click="guest(item.id,!item.guest)"/>
              </template>
            </v-data-table>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('close')"
          >
            {{editable&&newTeam?'Mégsem':'Bezárás'}}
          </v-btn>
          <span v-if="team.name.length>0&&team.AgeGroupId!==undefined&&team.KidAthletes.length>0&&team.Coaches.length>0">
            <v-btn
              color="blue darken-1"
              text
              v-if="newTeam"
              @click="applyNewTeam()"
            >
              Hozzáadás
            </v-btn>
            <!--<v-btn
              color="blue darken-1"
              text
              v-else-if="editable"
              @click="$emit('change')"
            >
              Módosítások mentése
            </v-btn>-->
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogs.addKidAthlete.show"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-text>
          <span class="headline">Kölyökatléta hozzáadása</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-checkbox
              v-if="$store.getters.userRole>=roles.clubAdmin&&!dialogs.addKidAthlete.data.club"
              label="Minden versenyző az egyesületemből"
              v-model="dialogs.addKidAthlete.data.allKidAthletesFromMyClub"
              @click="getKidAthletes()"
            />
            <v-select
              :readonly="!editable"
              v-model="dialogs.addKidAthlete.selectedKidAthlete"
              label="Kölyökatléta"
              rounded
              outlined
              prepend-inner-icon="fa-child"
              no-data-text="Nem található kölyökatléta a megadott korcsoportban"
              :items="kidAthletes.filter(KA => team.KidAthletes.findIndex(ka => ka.id==KA.id)==-1&&(ageGroups.find(aG => aG.id==team.AgeGroupId)?KA.ageByYears<=ageGroups.find(aG => aG.id==team.AgeGroupId).maxAge:false)).map(KA => {
                return {
                  text: `${KA.fullname} - ${KA.Group.name}`,
                  id: KA.id
                }
                })"
              item-text="text"
              item-value="id"
            />
            <v-checkbox
              label="Vendég (NEM TAGJA EGYESÜLETNEK)"
              v-model="dialogs.addKidAthlete.guest"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.addKidAthlete.show=false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addKidAthlete"
          >
            Hozzáadás
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogs.addCoach.show"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-text>
          <span class="headline">Edző hozzáadása</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-select
              v-model="dialogs.addCoach.selectedCoach"
              label="Edző"
              rounded
              outlined
              prepend-inner-icon="fa-user"
              no-data-text="Nem található edző"
              :items="coaches.filter(C => team.Coaches.findIndex(c => c.id==C.id)==-1).map(C => {
                return {
                  text: `${C.fullname}`,
                  id: C.id
                }
                })"
              item-text="text"
              item-value="id"
            />

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.addCoach.show=false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addCoach"
          >
            Hozzáadás
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ['competitionId', 'editable', 'team', 'show', "newTeam", "ageGroups", 'clubId'],
  data: function(){
    return {
      tables: {
        kidAthletes: {
          headers: [
            {
              text: "Sorszám",
              align: "center",
              sortable: true,
              filterable: false,
              groupable: false,
              value: 'serialNumber'
            },
            {
              text: "Név",
              align: "center",
              sortable: false,
              filterable: false,
              groupable: false,
              value: 'fullname'
            },
            {
              text: 'Születési hely',
              align: 'center',
              sortable: false,
              filterable: false,
              groupable: false,
              value: 'birthplace'
            },
            {
              text: 'Születési dátum',
              align: 'center',
              sortable: false,
              filterable: false,
              groupable: false,
              value: 'birthdate'
            },
            {
              text: "Kor",
              align: "center",
              sortable: false,
              filterable: false,
              groupable: false,
              value: 'ageByYears'
            },
            {
              text: "Vendég",
              align: "center",
              sortable: false,
              filterable: false,
              groupable: false,
              value: "guest"
            },
            {
              text: "Műveletek",
              align: "center",
              sortable: false,
              filterable: false,
              groupable: false,
              value: "actions"
            }
          ]
        }
      },
      dialogs: {
        addKidAthlete: {
          show: false,
          selectedKidAthlete: null,
          data: {
            club: null,
            allKidAthletesFromMyClub: false,
          },
          guest: false
        },
        addCoach: {
          show: false,
          selectedCoach: null
        }
      },
      coaches: [],
      kidAthletes: [],
    }
  },
  computed: {

  },
  watch: {
    show: function(newS, oldS) {
      if(newS) {
        if(this.clubId) {
          if(this.clubId!=this.dialogs.addKidAthlete.data.club) {
            this.dialogs.addKidAthlete.data.club=this.clubId;
            this.kidAthletes=[];
          }
        }
        else if(this.editable&&this.newTeam) this.team.Coaches.push({id: this.$store.getters.userId, fullname: this.$store.getters.userFullname})
      } else {
        this.coaches = []
      }
    }
  },
  methods: {
    guestLocal: function(kidAthleteId, newGuest) {
      this.team.KidAthletes.find(KA => KA.id==kidAthleteId).KidAthlete_Team.guest=newGuest;
    },
    guest: function(kidAthleteId, newGuest) {
      if(this.newTeam) this.guestLocal(kidAthleteId,newGuest);
      else this.axios({url: `competition/${this.competitionId}/team/${this.team.id}/changeGuest/${kidAthleteId}/${newGuest?1:0}`, method: 'PUT'}).then(response => {
        if(response.data.success) this.guestLocal(kidAthleteId,newGuest);
        else this.$store.commit('setSnack', 'A módosítás nem sikerült.')
      })
    },
    moveLocal: function(kidAthleteId, kidAthleteSerialNumber, moveTo) {
      this.team.KidAthletes.forEach(KA => {
        if(KA.id==kidAthleteId) KA.KidAthlete_Team.serialNumber=moveTo;
        else if(kidAthleteSerialNumber<moveTo&&KA.KidAthlete_Team.serialNumber<=moveTo&&KA.KidAthlete_Team.serialNumber>kidAthleteSerialNumber) KA.KidAthlete_Team.serialNumber--
        else if(kidAthleteSerialNumber>moveTo&&KA.KidAthlete_Team.serialNumber>=moveTo&&KA.KidAthlete_Team.serialNumber<kidAthleteSerialNumber) KA.KidAthlete_Team.serialNumber++
      })
    },
    move: function(kidAthleteId, kidAthleteSerialNumber, moveTo) {
      if(this.newTeam) this.moveLocal(kidAthleteId, kidAthleteSerialNumber, moveTo)
      else this.axios({url: `competition/${this.competitionId}/team/${this.team.id}/move/${kidAthleteId}/${kidAthleteSerialNumber}/${moveTo}`, method: 'PUT'}).then(response => {
        if(response.data.success) this.moveLocal(kidAthleteId, kidAthleteSerialNumber, moveTo)
        else this.$store.commit('setSnack', 'A mozgatás nem sikerült.')
      })

    },
    applyNewTeam: function() {
      var data = {
        team: {
          AgeGroupId: this.team.AgeGroupId,
          name: this.team.name
        },
        KidAthletes_Team: this.team.KidAthletes.map(KA => {
          return {
            id: KA.id,
            serialNumber: KA.KidAthlete_Team.serialNumber,
            guest: KA.KidAthlete_Team.guest
          }
        }),
        coaches: this.team.Coaches.map(C => C.id)
      }
      if(this.clubId) data.KAClubId=this.clubId;
      this.axios({url: `competition/${this.competitionId}/applyTeam`, method: 'POST', data}).then(response => {
        if(response.data.success) {
          this.team.id=response.data.data.id,
          this.team.KAClub=response.data.data.KAClub
          this.$emit("newTeam")
          this.$emit("close")
        }
      })
    },
    deleteCoach: function(coachId) {
      if(this.newTeam) this.team.Coaches.splice(this.team.Coaches.findIndex(c => c.id==coachId))
      else {
        this.axios({url: `competition/${this.competitionId}/team/${this.team.id}/coach/${coachId}`, method: "DELETE"}).then(response => {
          if(response.data.success) this.team.Coaches.splice(this.team.Coaches.findIndex(c => c.id==coachId))
        })
      }
    },
    addCoachDialog: function() {
      if(this.coaches.length==0)
        this.getCoaches().then(() => {
          this.dialogs.addCoach.show=true;
        })
      else this.dialogs.addCoach.show=true
    },
    addCoach: function() {
      const coach = {...this.coaches.find(c => c.id==this.dialogs.addCoach.selectedCoach)}
      if(this.newTeam) {
        this.team.Coaches.push({...coach})
        this.dialogs.addCoach.show=false
      }
      else {
        this.axios({url: `competition/${this.competitionId}/team/${this.team.id}/coach`, method: "PUT", data: {coachId: coach.id}}).then((response) => {
          if(response.data.success) {
            this.team.Coaches.push({...coach})
            this.dialogs.addCoach.show=false
          }
        })
      }
    },
    getCoaches: function() {
      return new Promise((success, error) => {
        this.axios({url: `user/coachList`, method: 'GET', params: this.clubId?{clubId: this.clubId}:{}}).then(response => {
          if(response.data.success) {
            this.coaches=response.data.data.coaches
            success();
          }
          else error()
        })
      })
    },
    addKidAthleteDialog: function() {
      if(this.kidAthletes.length==0)
        this.getKidAthletes().then(() => {
          this.dialogs.addKidAthlete.show=true;
        })
      else this.dialogs.addKidAthlete.show=true
    },
    getKidAthletes: function() {
      return new Promise((success, error) => {
        this.axios({url: `kidathlete/getKidAthletesForCompetition/${this.competitionId}`, method: 'GET', params: this.dialogs.addKidAthlete.data}).then(response => {
          if(response.data.success) {
            this.kidAthletes=response.data.data.kidAthletes
            success();
          }
          else error()
        })
      })
    },
    addKidAthlete: function() {
      var KA = {...this.kidAthletes.find(ka => ka.id==this.dialogs.addKidAthlete.selectedKidAthlete)}
      delete KA.Group;
      KA.KidAthlete_Team = {serialNumber: this.team.KidAthletes.length+1, guest: this.dialogs.addKidAthlete.guest}
      if(this.newTeam) {
        this.team.KidAthletes.push({...KA})
        this.dialogs.addKidAthlete.show=false;
      }
      else {
        this.axios({url: `competition/${this.competitionId}/team/${this.team.id}/kidAthlete`, method: "PUT", data: {kidAthleteId: KA.id, guest: this.dialogs.addKidAthlete.guest}}).then((response) => {
          if(response.data.success) {
            this.team.KidAthletes.push({...KA})
            this.dialogs.addKidAthlete.show=false
          }
        })
      }
    },
    deleteKALocal: function(KAId, serialNumber) {
      this.team.KidAthletes.splice(this.team.KidAthletes.findIndex(KA => KA.id==KAId),1)
      this.team.KidAthletes.forEach(KA => {
        if(KA.KidAthlete_Team.serialNumber>serialNumber) KA.KidAthlete_Team.serialNumber--
      })
    },
    deleteKidAthlete: function(KAId, serialNumber) {
      if(this.newTeam) {
        this.deleteKALocal(KAId, serialNumber)
      } else {
        this.axios({url: `competition/${this.competitionId}/team/${this.team.id}/kidAthlete/${KAId}`, method: "DELETE"}).then(response => {
          if(response.data.success) this.deleteKALocal(KAId, serialNumber)
        })
      }
    }
  },
  mounted() {

  }
}


</script>
