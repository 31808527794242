<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr v-for="r in tables.details.data" :key="r.text">
          <th style="text-align: right">{{r.text}}</th>
          <td :colspan="(r.editable&&competition.status==-1&&editable===true)?1:2" style="text-align: center">{{r.computed?computedValue(r.value).computed:competition[r.value]}}</td>
          <td v-if="r.editable&&competition.status==-1&&editable===true">
            <v-btn rounded color="blue" @click="dialog('editDetail',r.value, r.computed?computedValue(r.value).original:competition[r.value])"><v-icon>fa-edit</v-icon>Módosítás</v-btn>
          </td>
        </tr>
        <tr>
          <th style="text-align: right">Csak a meghívott egyesületek regisztrálhatnak</th>
          <td colspan="2" style="text-align: center">
            <v-checkbox :readonly="true" @click="changeInvitationalApplication" v-model="competition.invitationalApplication"/>
            <span v-if="competition.invitationalApplication && editable">EBBEN AZ ESETBEN HÍVJA MEG SAJÁT EGYESÜLETÉT IS, HA SZERETNÉ, HOGY NEVEZNI TUDJON!</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog
      v-model="dialogs.editDetail.show"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-text>
          <span class="headline">{{dialogs.editDetail.changedProperty=="name"?"Név":(dialogs.editDetail.changedProperty=="description"?"Leírás":(dialogs.editDetail.changedProperty=="level"?"Szint":(dialogs.editDetail.changedProperty=="place"?"Helyszín":(dialogs.editDetail.changedProperty=='date'?"Időpont":(dialogs.editDetail.changedProperty=='applicationDeadline'?'Nevezési határidő': 'Maximum csapatok számának')))))}} módosítása</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-text-field
              v-if="dialogs.editDetail.changedProperty=='name'||dialogs.editDetail.changedProperty=='place'"
              :prepend-inner-icon="dialogs.editDetail.changedProperty=='name'?'fa-trophy':(dialogs.editDetail.changedProperty=='place'?'fa-map-marker':'')"
              :label="dialogs.editDetail.changedProperty=='Név'?'fa-trophy':(dialogs.editDetail.changedProperty=='place'?'Helyszín':'')"
              v-model="dialogs.editDetail.changedValue"
              rounded
              outlined
            />
            <v-textarea
              v-else-if="dialogs.editDetail.changedProperty=='description'"
              prepend-inner-icon="fa-info-circle"
              label="Leírás"
              v-model="dialogs.editDetail.changedValue"
              rounded
              outlined
            />
            <v-select
              v-else-if="dialogs.editDetail.changedProperty=='level'"
              prepend-inner-icon="fa-globe-europe"
              label="Szint"
              v-model="dialogs.editDetail.changedValue"
              :items="competitionLevels"
              item-text="name"
              item-value="level"
              rounded
              outlined
            />
            <v-text-field
              v-else-if="dialogs.editDetail.changedProperty=='maxTeamCount'"
              prepend-inner-icon="fa-users"
              label="Maximum csapatok"
              v-model="dialogs.editDetail.changedValue"
              rounded
              outlined
              :rules="[fieldRules.isNumber]"
            />
            <span v-else-if="dialogs.editDetail.changedProperty=='date'">
              <v-menu
                v-model="dialogs.editDetail.date.menus.date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogs.editDetail.date.date"
                    label="Időpont"
                    prepend-inner-icon="fa-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="dialogs.editDetail.date.date"
                  :first-day-of-week="1"
                  @input="dialogs.editDetail.date.menus.date = false"
                />
              </v-menu>
              <v-menu
                v-model="dialogs.editDetail.date.menus.time"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogs.editDetail.date.time"
                    label="Időpont"
                    prepend-inner-icon="fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                  />
                </template>
                <v-time-picker
                  v-if="dialogs.editDetail.date.menus.time"
                  v-model="dialogs.editDetail.date.time"
                  @click:minute="dialogs.editDetail.date.menus.time = false"
                  format="24hr"
                />
              </v-menu>
            </span>
            <span v-else-if="dialogs.editDetail.changedProperty=='applicationDeadline'">
              <v-menu
                v-model="dialogs.editDetail.applicationDeadline.menus.date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogs.editDetail.applicationDeadline.date"
                    label="Nevezési határidő"
                    prepend-inner-icon="fa-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="dialogs.editDetail.applicationDeadline.date"
                  :first-day-of-week="1"
                  @input="dialogs.editDetail.applicationDeadline.menus.date = false"
                />
              </v-menu>
              <v-menu
                v-model="dialogs.editDetail.applicationDeadline.menus.time"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogs.editDetail.applicationDeadline.time"
                    label="Nevezési határidő"
                    prepend-inner-icon="fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    outlined
                  />
                </template>
                <v-time-picker
                  v-if="dialogs.editDetail.applicationDeadline.menus.time"
                  v-model="dialogs.editDetail.applicationDeadline.time"
                  @click:minute="dialogs.editDetail.applicationDeadline.menus.time = false"
                  format="24hr"
                />
              </v-menu>
            </span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.editDetail.show = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="editDetail()"
          >
            Módosítás
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import competitionLevelNames from '../plugins/competitionLevelNames';
import roles_CompetitionLevels from '../plugins/roles-CompetitionLevels'
import competitionStatusNames from '../plugins/competitionStatusNames'


export default {
  props: ['id',  'competition', 'editable'],

  data: function(){
    return {
      competitionLevelNames,
      competitionStatusNames,
      dialogs: {
        editDetail: {
          show: false,
          changedProperty: '',
          changedValue: '',
          date: {
            date: this.addMonths(new Date(), 2).toISOString().substring(0,10),
            time: '13:00',
            menus: {
              date: false,
              time: false
            }
          },
          applicationDeadline: {
            date: this.addMonths(new Date(), 1).toISOString().substring(0,10),
            time: '00:00',
            menus: {
              date: false,
              time: false
            }
          }
        }
      },
      tables: {
        details: {
          data: [
            {
              value: 'name',
              text: 'Név',
              editable: true,
              computed: false
            },
            {
              value: 'description',
              text: 'Leírás',
              editable: true,
              computed: false
            },
            {
              value: 'levelName',
              text: 'Szint',
              editable: true,
              computed: true
            },
            {
              value: 'place',
              text: 'Helyszín',
              editable: true,
              computed: false
            },
            {
              value: 'date',
              text: 'Időpont',
              editable: true,
              computed: false
            },
            {
              value: 'maxTeamCount',
              text: 'Maximum csapatok száma',
              editable: true,
              computed: false
            },
            {
              value: 'applicationDeadline',
              text: 'Nevezési határidő',
              editable: true,
              computed: false
            },
            {
              value: 'statusName',
              text: 'Állapot',
              editable: false,
              computed: true
            },
          ]
        }
      }
    }
  },
  computed: {
    levelName() {
      return {
        original: this.competition.level,
        computed: this.competitionLevelNames[this.competition.level]
      }
    },
    statusName() {
      return {
        original: this.competition.status,
        computed: this.competitionStatusNames[this.competition.status]
      }
    },
    competitionLevels() {
      return roles_CompetitionLevels[this.$store.getters.userRole].map(cL => {
        return {
          level: cL,
          name: competitionLevelNames[cL]
        }
      })
    }
  },
  watch: {

  },
  methods: {
    changeInvitationalApplication: function() {
      if(this.competition.status==-1&&this.editable===true) {
        this.dialogs.editDetail.changedProperty="invitationalApplication";
        this.dialogs.editDetail.changedValue = !this.competition.invitationalApplication;
        this.editDetail();
      }
    },
    dialog: function(dialog, data, value) {
      switch(dialog) {
        case 'editDetail':
          if(data=="levelName") data="level"


          this.dialogs.editDetail.changedProperty=data
          this.dialogs.editDetail.changedValue=value
          this.dialogs.editDetail.show=true
        break;
      }
    },
    editDetail: function() {
      var changed = {competition: {}};
      changed.competition[this.dialogs.editDetail.changedProperty]=this.dialogs.editDetail.changedProperty=='date'?this.dialogs.editDetail.date.date+" "+ this.dialogs.editDetail.date.time:(this.dialogs.editDetail.changedProperty=='applicationDeadline'?this.dialogs.editDetail.applicationDeadline.date+" "+ this.dialogs.editDetail.applicationDeadline.time:this.dialogs.editDetail.changedValue);
      this.axios({url: "competition/"+this.$route.params.id, method: "PUT", data: {...changed}}).then((response) => {
        if(response.data.success) {
          this.dialogs.editDetail.show=false;
          this.competition[this.dialogs.editDetail.changedProperty] = changed.competition[this.dialogs.editDetail.changedProperty]
          this.$store.commit('setSnack','A módosítás sikeresen megtörtént')

        }
      })
    },
    computedValue: function(value) {
      return this[value]
    }
  }
}


</script>
