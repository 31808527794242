<template>
  <div>
    <span v-if="!visitor">
      <h1>Kiírássablon</h1>
      <p>A szoftver elkészíti az adatok alapján a kiírás sablonját, amit letöltve szerkeszthet, a végleges változatot töltse fel PDF formátumban</p>
      <v-btn color="primary" rounded :href="`${this.axios.defaults.baseURL}competition/${competitionId}/docx/announcement`" target="_blank"><v-icon>fa-download</v-icon>Kiírássablon letöltése</v-btn>
    </span>
    <h1>Kiírás{{visitor||!editable?"":" kezelése"}}</h1>
    <span v-if="hasAnnouncement">
      <v-btn color="success" rounded :href="`${this.axios.defaults.baseURL}competition/${competitionId}/announcement`" target="_blank"><v-icon>fa-download</v-icon>Letöltés</v-btn>
      <v-btn color="red accent-4" rounded v-if="editable" @click="deleteAnnouncement()"><v-icon>fa-trash</v-icon>Kiírás törlése</v-btn>
    </span>
    <span v-else>
      <v-btn color="success" rounded v-if="editable" @click="dialogs.addAnnouncement.show=true"><v-icon>fa-upload</v-icon>Kész kiírás feltöltése PDF-ben</v-btn>
      <span v-else>NINCS KIÍRÁS FELTÖLTVE!!!</span>
    </span>
    <v-dialog
      v-model="dialogs.addAnnouncement.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Kiírás feltöltése PDF-ben</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-file-input
              v-model="dialogs.addAnnouncement.file"
              rounded
              label="Kiírás"
              outlined
              accept="application/pdf"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.addAnnouncement.show = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            v-if="dialogs.addAnnouncement.file"
            @click="addAnnouncement()"
          >
            Feltöltés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'announcement',
  props: [
    "competitionId",
    "editable",
    "visitor"
  ],
  data () {
    return {
      hasAnnouncement: false,
      dialogs: {
        addAnnouncement: {
          show: false,
          file: null
        }
      }
    }
  },
  computed: {

  },
  methods: {
    addAnnouncement: function() {
      var formData = new FormData();
      formData.append('file',this.dialogs.addAnnouncement.file);
      this.axios.post(`competition/${this.competitionId}/announcement`, formData, {headers: { 'Content-Type': 'multipart/form-data'}}).then((response) => {
        if(response.data.success) {
          this.dialogs.addAnnouncement.show=false;
          this.dialogs.addAnnouncement.file=null
          this.$store.commit('setSnack', 'Sikeresen feltöltve.')
          this.hasAnnouncement=true
        }
      })
    },
    deleteAnnouncement: function() {
      this.axios({url: `competition/${this.competitionId}/announcement`, method: 'DELETE'}).then(response => {
        if(response.data.success) {
          this.hasAnnouncement=false;
          this.$store.commit('setSnack', 'Sikeresen törölve.')
        }
      })
    },
    getHasAnnouncement: function() {
      this.axios({url: `competition/${this.competitionId}/hasAnnouncement`, method: 'GET'}).then(response => {
        if(response.data.success) this.hasAnnouncement=response.data.data.hasAnnouncement;
      })
    }
  },
  mounted() {
    this.getHasAnnouncement();
  }
}
</script>
