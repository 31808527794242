<template>
  <div>
    <v-row>
      <v-col cols="12" :sm="6">
        <h1>Szervező {{organizers.length==1?"személy":"személyek"}}</h1>
        <p v-if="editable">A szervező személyek teljes mértékben szerkeszthetik a versenyt</p>
        <v-data-table
          :items="organizers"
          :headers="tables.organizers.headers"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:top>
            <v-tooltip top v-if="editable">
              Társszervező hozzáadása
              <template v-slot:activator="{on, attrs}">
                <v-btn v-on="on" v-bind="attrs" fab @click="dialogs.addOrganizer.show=true" color="success"><v-icon>fa-user-plus</v-icon></v-btn>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:item.delete="{item}">
            <v-tooltip top>
              {{item.id==$store.getters.userId?'Önmagát nem törölheti':'Társszervező törlése'}}
              <template v-slot:activator="{on, attrs}">
                <v-btn v-on="on" v-bind="attrs" icon v-if="item.id==$store.getters.userId" color="grey lighten-1"><v-icon>fa-trash</v-icon></v-btn>
                <v-btn v-on="on" v-bind="attrs" icon v-else color="red accent-4" @click="removeOrganizer(item.id)"><v-icon>fa-trash</v-icon></v-btn>

              </template>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" :sm="6">
        <h1>{{level==3?"Országos verseny":(level==2?(organizerRegion!==undefined?`Szervező régió: ${organizerRegion.name}`:"Válasszon régiót!"):(organizerClub!==undefined?`Szervező egyesület: ${organizerClub.MIRClub.name}`:"Válasszon egyesületet!"))}}</h1>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogs.addOrganizer.show"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-text>
          <span class="headline">Társszervező hozzáadása</span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-select
              v-model="dialogs.addOrganizer.organizerId"
              :items="choosableOrganizers"
              item-text="fullname"
              item-value="id"
              prepend-inner-icon="fa-user-tie"
              outlined
              rounded
              label="Társszervező"
              no-data-text="Társszervezőként hozzáadható felhasználó nem található"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogs.addOrganizer.show=false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addOrganizer()"
            v-if="dialogs.addOrganizer.organizerId!==undefined"
          >
            Hozzáadás
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'invitations',
  props: [
    "competitionId",
    "editable",
    "organizers",
    "organizerClub",
    "organizerRegion",
    "level",
    "choosableOrganizers"
  ],
  data () {
    return {
      tables: {
        organizers: {
          headers: [
            {
              text: 'Szervező',
              align: 'center',
              sortable: false,
              filterable: false,
              groupable: false,
              value: 'fullname'
            }
          ].concat(this.editable?[{
            text: 'Törlés',
            align: 'center',
            sortable: false,
            filterable: false,
            groupable: false,
            value: 'delete'
          }]:[])
        }
      },
      dialogs: {
        addOrganizer: {
          show: false,
          organizerId: undefined
        }
      }
    }
  },
  computed: {

  },
  methods: {
    addOrganizer: function() {
      this.axios({url: `competition/${this.competitionId}/organizer`, method: 'POST', data: {organizerId: this.dialogs.addOrganizer.organizerId}}).then(response =>{
        if(response.data.success) {
          this.organizers.push(this.choosableOrganizers.find(o => o.id==this.dialogs.addOrganizer.organizerId))
          this.dialogs.addOrganizer.organizerId=undefined
          this.dialogs.addOrganizer.show=false
        }
      })
    },
    removeOrganizer: function(organizerId) {
      this.axios({url: `competition/${this.competitionId}/organizer/${organizerId}`, method: "DELETE"}).then(response => {
        if(response.data.success) {
          this.organizers.splice(this.organizers.findIndex(o => o.id==organizerId),1)
          this.$store.commit('setSnack', 'Törölve.')
        }
      })
    }
  },
  mounted() {

  }
}
</script>
