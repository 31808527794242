var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.appliedTeams},scopedSlots:_vm._u([{key:"item.ageGroup",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ageGroupColumn(item.AgeGroupId))+" ")]}},{key:"item.clubOrSchool",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.KAClub?item.KAClub.MIRClub.name:'-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.viewTeam(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.editable? "fa-edit":"fa-eye"))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.editable? "Megtekintés és szerkesztés":"Megtekintés")+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.canDeleteTeam,"color":"red accent-4"},on:{"click":function($event){_vm.dialogs.deleteTeam.teamId=item.id, _vm.dialogs.deleteTeam.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-trash")])],1)]}}],null,true)},[_vm._v(" Törlés ")])]}},{key:"item.coaches",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',_vm._l((item.Coaches),function(coach){return _c('v-chip',{key:coach.id,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(coach.fullname)+" ")])}),1)]}}])}),_c('appliedTeamDetail',{attrs:{"clubId":_vm.dialogs.editTeam.clubId,"competitionId":_vm.competitionId,"ageGroups":_vm.ageGroups,"editable":_vm.editable,"newTeam":false,"team":_vm.dialogs.editTeam.editedTeam,"show":_vm.dialogs.editTeam.show},on:{"close":function($event){_vm.dialogs.editTeam.show=false},"change":function($event){_vm.dialogs.editTeam.show=false}}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogs.deleteTeam.show),callback:function ($$v) {_vm.$set(_vm.dialogs.deleteTeam, "show", $$v)},expression:"dialogs.deleteTeam.show"}},[_c('v-card',[_c('v-card-text',[_c('span',{staticClass:"headline"},[_vm._v("Csapat törlése")])]),_c('v-card-text',[_c('v-container',[_vm._v(" Biztosan szeretné törölni a csapat nevezését? ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogs.deleteTeam.show=false}}},[_vm._v(" Mégsem ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteTeam()}}},[_vm._v(" Törlés ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }