<template>
  <div>
    <v-tabs>
      <v-tab v-for="aG in ageGroups" :key="aG.id">{{aG.shortname}} - {{aG.name}}</v-tab>

      <v-tab-item v-for="aG in ageGroups" :key="aG.id">
        <v-data-table
          v-if="competitionExercises"
          :headers="tableHeaders"
          :items="tableExercises(aG.id)"
          >
          <template v-slot:top v-if="editable">
            <v-tooltip top>
              Hozzáadás
              <template v-slot:activator="{on, attrs}">
                <v-btn fab :disabled="competitionStatus!=-1" color="success" v-on="on" v-bind="attrs" @click="dialogs.addExercise.show=true, dialogs.addExercise.exercise.AgeGroupId=aG.id"><v-icon>fa-plus</v-icon></v-btn>
              </template>
            </v-tooltip>
            {{competitionStatus==-1?"":"Gyakorlatokat csak az előkészítés alatt lehet hozzáadni/törölni"}}
          </template>

          <template v-slot:item.eMUId="{item}">
            <v-btn text :to="{name: 'exercise', params: {id: exerciseMUs.find(eMU => eMU.id==item.eMUId).ExerciseId}}">{{exercises.find(e => e.id==exerciseMUs.find(eMU => eMU.id==item.eMUId).ExerciseId).name}}</v-btn>
          </template>
          <template v-slot:item.competitionHelperId="{item}">
            {{competitionHelpers.find(u =>u.id==item.competitionHelperId) ? competitionHelpers.find(u =>u.id==item.competitionHelperId).fullname : "MÁSIK SZERVEZŐ ADTA MEG"}}
          </template>
          <template v-slot:item.actions="{item}">
            <v-tooltip top>
              Törlés
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="red accent-4" :disabled="competitionStatus!=-1" v-on="on" v-bind="attrs" @click="dialogs.deleteExercise.AgeGroupId=aG.id, dialogs.deleteExercise.ExerciseMeasurementUnitId=item.eMUId, dialogs.deleteExercise.show=true"><v-icon>fa-trash</v-icon></v-btn>
              </template>
            </v-tooltip>
            <v-tooltip top>
              Módosítás
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="primary"  v-on="on" v-bind="attrs" @click="dialogs.changeExercise.AgeGroupId=aG.id, dialogs.changeExercise.ExerciseMeasurementUnitId=item.eMUId, dialogs.changeExercise.changed.competitionHelperId=item.competitionHelperId, dialogs.changeExercise.changed.maxTeamInOneTime=item.maxTeamInOneTime, dialogs.changeExercise.show=true"><v-icon>fa-edit</v-icon></v-btn>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogs.addExercise.show"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-text>
              <span class="headline">Gyakorlat hozzáadása</span>
            </v-card-text>
            <v-card-text>
              <v-container>
                <v-select
                  prepend-inner-icon="fa-dumbbell"
                  rounded
                  outlined
                  label="Gyakorlat"
                  :items="selectableExercises"
                  item-text="name"
                  item-value="eMUId"
                  v-model="dialogs.addExercise.exercise.ExerciseMeasurementUnitId"
                />
                <v-select
                  prepend-inner-icon="fa-user"
                  rounded
                  outlined
                  label="Versenysegítő"
                  :items="competitionHelpers"
                  item-text="fullname"
                  item-value="id"
                  v-model="dialogs.addExercise.exercise.competitionHelperId"
                />
                <v-text-field
                  prepend-inner-icon="fa-users"
                  rounded
                  outlined
                  label="Maximum csapat egy időben"
                  v-model="dialogs.addExercise.exercise.maxTeamInOneTime"
                  :rules="[fieldRules.required,fieldRules.isNumber]"
                />
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogs.addExercise.show = false"
              >
                Mégsem
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="addExercise()"
              >
                Hozzáadás
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogs.deleteExercise.show"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-text>
            <span class="headline">Gyakorlat törlése</span>
          </v-card-text>
          <v-card-text>
            <v-container>
              Biztosan szeretné törölni a gyakorlatot a versenyből?
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogs.deleteExercise.show = false"
            >
              Mégsem
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="deleteExercise()"
            >
              Törlés
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogs.changeExercise.show"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-text>
            <span class="headline">Gyakorlat módosítása</span>
          </v-card-text>
          <v-card-text>
            <v-container>
              <v-select
                prepend-inner-icon="fa-user"
                rounded
                outlined
                label="Versenysegítő"
                :items="competitionHelpers"
                item-text="fullname"
                item-value="id"
                v-model="dialogs.changeExercise.changed.competitionHelperId"
              />
              <v-text-field
                prepend-inner-icon="fa-users"
                rounded
                outlined
                label="Maximum csapat egy időben"
                v-model="dialogs.changeExercise.changed.maxTeamInOneTime"
                :rules="[fieldRules.required,fieldRules.isNumber]"
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogs.changeExercise.show = false"
            >
              Mégsem
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="changeExercise()"
            >
              Módosítás
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  </div>
</template>

<script>
export default {
  props: [
    'competitionHelpers',
    'exercises',
    'exerciseMUs',
    'measurementUnits',
    'ageGroups',
    'competitionExercises',
    'competitionId',
    'competitionStatus',
    'editable'
  ],
  data () {
    return {
      tableHeaders: [
        {
          text: 'Gyakorlat',
          align: 'center',
          sortable: false,
          filterable: false,
          groupable: false,
          value: 'eMUId'
        },
        {
          text: 'Maximum csapat egy időben',
          align: 'center',
          sortable: false,
          filterable: false,
          groupable: false,
          value: 'maxTeamInOneTime'
        }
      ].concat(this.competitionHelpers?[{
            text: 'Versenysegítő',
            align: 'center',
            sortable: false,
            filterable: false,
            groupable: false,
            value: 'competitionHelperId'
          }]:[]).concat(this.editable?[{
            text: "Műveletek",
            align: 'center',
            sortable: false,
            filterable: false,
            groupable: false,
            value: 'actions'
          }]:[]),
      dialogs: {
        addExercise: {
          show: false,
          exercise: {
            ExerciseMeasurementUnitId: -1,
            AgeGroupId: -1,
            competitionHelperId: -1,
            maxTeamInOneTime: 1,
          }
        },
        deleteExercise: {
          show: false,
          ExerciseMeasurementUnitId: -1,
          AgeGroupId: -1
        },
        changeExercise: {
          show: false,
          ExerciseMeasurementUnitId: -1,
          AgeGroupId: -1,
          changed: {
            maxTeamInOneTime: 1,
            competitionHelperId: 1
          }
        }
      }
    }
  },
  computed: {
      selectableExercises() {
        var selectableExercises = this.exerciseMUs.filter(eMU => eMU.Criteria.filter( C => C.AgeGroupId== this.dialogs.addExercise.exercise.AgeGroupId).length>0 &&
                                       this.competitionExercises.filter(cE => cE.AgeGroupId==this.dialogs.addExercise.exercise.AgeGroupId&&cE.Exercise_MeasurementUnit.id==eMU.id).length==0).map(eMU => {
          return {
            eMUId: eMU.id,
            name: this.exercises.find(e =>e.id==eMU.ExerciseId).name+" ("+this.measurementUnits.find(mU=> mU.id==eMU.MeasurementUnitId).unit+")"
          }
        });

        return selectableExercises.sort((a,b) => {
          if(a.name>b.name) return 1;
          if(a.name<b.name) return -1;
          return 0;
        })
      }
  },
  methods: {
    addExercise: function() {
      this.axios({url: "/competition/"+this.competitionId+"/exercise", method: "POST", data: {exercise: {...this.dialogs.addExercise.exercise}}}).then((response) => {
        if(response.data.success) {
          this.competitionExercises.push({
            AgeGroupId: this.dialogs.addExercise.exercise.AgeGroupId,
            helperId: this.dialogs.addExercise.exercise.competitionHelperId,
            maxTeam: this.dialogs.addExercise.exercise.maxTeamInOneTime,
            Exercise_MeasurementUnit: {
              id: this.dialogs.addExercise.exercise.ExerciseMeasurementUnitId,
            }
          })
          this.dialogs.addExercise.show=false
          this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént')

        }
      })
    },
    deleteExercise() {
      this.axios({url: "/competition/"+this.competitionId+"/exercise/"+this.dialogs.deleteExercise.AgeGroupId+"/"+this.dialogs.deleteExercise.ExerciseMeasurementUnitId, method: "DELETE"}).then((response) =>{
        if(response.data.success) {
          this.competitionExercises.splice(this.competitionExercises.findIndex(cE => cE.AgeGroupId==this.dialogs.deleteExercise.AgeGroupId&&cE.Exercise_MeasurementUnit.id==this.dialogs.deleteExercise.ExerciseMeasurementUnitId),1)
          this.dialogs.deleteExercise.show=false;
          this.$store.commit('setSnack','A törlés sikeresen megtörtént')
        }
      })
    },
    changeExercise() {
      this.axios({url: "/competition/"+this.competitionId+"/exercise/"+this.dialogs.changeExercise.AgeGroupId+"/"+this.dialogs.changeExercise.ExerciseMeasurementUnitId, method: "PUT", data: {changed: {...this.dialogs.changeExercise.changed}}}).then((response) =>{
        if(response.data.success) {
          const exercise = this.competitionExercises.find(cE => cE.AgeGroupId==this.dialogs.changeExercise.AgeGroupId&&cE.Exercise_MeasurementUnit.id==this.dialogs.changeExercise.ExerciseMeasurementUnitId)
          exercise.helperId=this.dialogs.changeExercise.changed.competitionHelperId
          exercise.maxTeam=this.dialogs.changeExercise.changed.maxTeamInOneTime
          this.dialogs.changeExercise.show=false;
          this.$store.commit('setSnack','A módosítás sikeresen megtörtént')
        }
      })

    },
    tableExercises: function(ageGroupId){
      return this.competitionExercises.filter(cE => cE.AgeGroupId==ageGroupId).map(cE => {
        return {
          eMUId: cE.Exercise_MeasurementUnit.id,
          competitionHelperId: cE.helperId,
          maxTeamInOneTime: cE.maxTeam

        }
      })
    }
  }
}
</script>
