<template>
  <div>
    A legjobban teljesítő csapat annyi pontot kap, ahány csapat indult az adott korcsoportban. A pontok arányosan csökkennek egészen 1-ig, amit a legrosszabbul teljesítő csapat kap.
    <v-tabs>
      <v-tab v-for="AG in aG" :key="AG.id">{{AG.name}}</v-tab>

      <v-tab-item v-for="AG in aG" :key="AG.id">
        <v-data-table
          :headers="tableHeaders(AG)"
          :items="AG.Teams"
          hide-default-footer
          disable-pagination
          sort-desc
          sort-by="-1"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'competition-state',
  props: ['competitionId',],
  components: {

  },
  data: function(){
    return {
      aG: []
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    tableHeaders: function(ageGroup) {
      return [{
        text: 'Csapatnév',
        align: 'center',
        filterable: false,
        groupable: false,
        sortable: true,
        value: 'name'
      },
      {
        text: 'Egyesület/iskola',
        align: 'center',
        filterable: false,
        sortable: true,
        groupable: true,
        value: 'clubOrSchool'
      },
      {
        text: 'Létszám',
        align: 'center',
        filterable: false,
        groupable: false,
        sortable: false,
        value: 'kidAthleteCount'
      }].concat(...ageGroup.EMUCs.map(EMUC => {
        return {
          text: EMUC.exerciseName,
          align: 'center',
          filterable: false,
          groupable: false,
          sortable: true,
          value: `${EMUC.id}`
        }
      }))
    },
    getState: function() {
      this.axios({url: `competition/${this.competitionId}/state`, method: 'GET'}).then(response => {
        if(response.data.success) this.aG=response.data.data.ageGroups
      })
    }
  },
  mounted() {
    this.getState();
  }
}


</script>
